.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;
  
/* width */
::-webkit-scrollbar {
    width: 10px;
}
  
/* Track */
::-webkit-scrollbar-track {
    background: #transparent;
    border-radius: 5px;
}
  
/* Handle */
::-webkit-scrollbar-thumb {
    background: #transparent;
    border-radius: 5px;
}
  
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #transparent;
}

@import url("https://fonts.googleapis.com/css?family=Barlow|Tomorrow:400,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Orbitron:700");
@import url("https://fonts.googleapis.com/css?family=Jura:wght@700&display=swap");
@import url(https://fonts.googleapis.com/css?family=Roboto);

:root {
  --yellow-color: #fa9e2b;
}

.btn {
  width: 150px;
  height: 50px; 
  border: 0;
  outline: none;
  background-color: white;
  cursor: pointer;
  position: relative;
  font-family: Orbitron, sans-serif;
  font-size: .85rem;
  color: var(--yellow-color);
  clip-path: polygon(92% 0, 100% 25%, 100% 100%, 8% 100%, 0% 75%, 0 0);
}

.btn__content {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 1px;
  left: 1px;
  right: 1px;
  bottom: 1px;
  background-color: black;
  clip-path: polygon(92% 0, 100% 25%, 100% 100%, 8% 100%, 0% 75%, 0 0);
}

.glitch-wrapper {
   width: 100%;
   height: 100%;
   display: flex;
   text-align: left;
}

.glitch {
   position: relative;
   font-weight: 700;
   line-height: 1.2;
   color: #fff;
   letter-spacing: 5px;
   z-index: 1;
}

.glitch:before {
   content: attr(data-glitch);
   position: absolute;
   top: 0;
   left: -2px;
   width: 100%;
   color: #fff;
   overflow: hidden;
   clip: rect(0, 900px, 0, 0);
   animation: noise-before 3s infinite linear alternate-reverse;
}

.glitch:after {
   content: attr(data-glitch);
   position: absolute;
   top: 0;
   left: 2px;
   width: 100%;
   color: #fff;
   overflow: hidden;
   clip: rect(0, 900px, 0, 0);
   animation: noise-after 2s infinite linear alternate-reverse;
}

@keyframes noise-before {
   0% {
      clip: rect(61px, 9999px, 52px, 0);
   }

   5% {
      clip: rect(33px, 9999px, 144px, 0);
   }

   10% {
      clip: rect(121px, 9999px, 115px, 0);
   }

   15% {
      clip: rect(144px, 9999px, 162px, 0);
   }

   20% {
      clip: rect(62px, 9999px, 180px, 0);
   }

   25% {
      clip: rect(34px, 9999px, 42px, 0);
   }

   30% {
      clip: rect(147px, 9999px, 179px, 0);
   }

   35% {
      clip: rect(99px, 9999px, 63px, 0);
   }

   40% {
      clip: rect(188px, 9999px, 122px, 0);
   }

   45% {
      clip: rect(154px, 9999px, 14px, 0);
   }

   50% {
      clip: rect(63px, 9999px, 37px, 0);
   }

   55% {
      clip: rect(161px, 9999px, 147px, 0);
   }

   60% {
      clip: rect(109px, 9999px, 175px, 0);
   }

   65% {
      clip: rect(157px, 9999px, 88px, 0);
   }

   70% {
      clip: rect(173px, 9999px, 131px, 0);
   }

   75% {
      clip: rect(62px, 9999px, 70px, 0);
   }

   80% {
      clip: rect(24px, 9999px, 153px, 0);
   }

   85% {
      clip: rect(138px, 9999px, 40px, 0);
   }

   90% {
      clip: rect(79px, 9999px, 136px, 0);
   }

   95% {
      clip: rect(25px, 9999px, 34px, 0);
   }

   100% {
      clip: rect(173px, 9999px, 166px, 0);
   }
}

@keyframes noise-after {
   0% {
      clip: rect(26px, 9999px, 33px, 0);
   }

   5% {
      clip: rect(140px, 9999px, 198px, 0);
   }

   10% {
      clip: rect(184px, 9999px, 89px, 0);
   }

   15% {
      clip: rect(121px, 9999px, 6px, 0);
   }

   20% {
      clip: rect(181px, 9999px, 99px, 0);
   }

   25% {
      clip: rect(154px, 9999px, 133px, 0);
   }

   30% {
      clip: rect(134px, 9999px, 169px, 0);
   }

   35% {
      clip: rect(26px, 9999px, 187px, 0);
   }

   40% {
      clip: rect(147px, 9999px, 137px, 0);
   }

   45% {
      clip: rect(31px, 9999px, 52px, 0);
   }

   50% {
      clip: rect(191px, 9999px, 109px, 0);
   }

   55% {
      clip: rect(74px, 9999px, 54px, 0);
   }

   60% {
      clip: rect(145px, 9999px, 75px, 0);
   }

   65% {
      clip: rect(153px, 9999px, 198px, 0);
   }

   70% {
      clip: rect(99px, 9999px, 136px, 0);
   }

   75% {
      clip: rect(118px, 9999px, 192px, 0);
   }

   80% {
      clip: rect(1px, 9999px, 83px, 0);
   }

   85% {
      clip: rect(145px, 9999px, 98px, 0);
   }

   90% {
      clip: rect(121px, 9999px, 154px, 0);
   }

   95% {
      clip: rect(156px, 9999px, 44px, 0);
   }

   100% {
      clip: rect(67px, 9999px, 122px, 0);
   }
}

.colorlogo {
  height: 100%;
  font-family: Orbitron;
  background: linear-gradient(93.51deg, #fa9e2b 2.84%, white 99.18%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}

.cyberbutton {
  position: relative;
  font-family: "Orbitron", sans-serif;
  font-size: 1.25rem;
  border: none;
  padding: 1.2rem 3.5rem;
  border-radius: 2.5rem;
  letter-spacing: 0.3rem;
  text-transform: uppercase;
  color: white;
  background: linear-gradient(90deg, #09f0ed, #ff0efe, #5c54fd, #09f0ed);
  background-size: 400%;
  animation: neon 10s linear infinite;
  font-weight: bold;
}
.cyberbutton:after {
  position: absolute;
  content: "";
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: inherit;
  border-radius: 2.5rem;
  filter: blur(1px);
  z-index: -1;
  animation: glow 3s linear infinite;
}

@keyframes neon {
  from {
    background-position: 0%;
  }
  to {
    background-position: 400%;
  }
}

@keyframes glow {
  from {
    filter: blur(1px);
  }
  50% {
    filter: blur(20px);
  }
  to {
    filter: blur(1px);
  }
}

.card {
  background-color: var(--background);
  transition: 1000ms all;
  transform-origin: center left;
  overflow: hidden;
}

.card:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.card:hover ~ .card {
  font-weight: bold;
  cursor: pointer;
  transform: translateX(20px);
}